import React from "react"

import Layout from "../components/Layout"
import { AreaTable } from "../components/AreaTable"

const UTLAs = ({ location }) => (
  <Layout
    pathname={location.pathname}
    title="Cases – upper tier local authorities"
  >
    <AreaTable areaType="utla" rank nation />
  </Layout>
)

export default UTLAs
